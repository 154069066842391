<template>
  <v-card :key="`${active}`">
    <v-toolbar color="color1 color1Text--text">
      <v-toolbar-title>Custom Pool Template Wizard</v-toolbar-title>
    </v-toolbar>
    <v-window v-model="stepI" touchless>
      <!-- REGULAR -->
      <v-window-item :value="1">
        <v-card-text :class="{'pt-0': isNew}">
          <div class="text-right">
            <v-btn color="color3" text small @click="stepI = 3" v-if="isNew">kob</v-btn>
          </div>
          <v-autocomplete
            label="Pools Of"
            :items="[3,4,5,6,7,8,9,10,12,12,13,1415,16,17,18,19,20]"
            v-model="poolsOf"
            hint="Select or enter the number of teams this template is for"
            persistent-hint
          ></v-autocomplete>
          <v-checkbox label="Allow Duplicate Match Ups" v-model="allowDups" color="success"></v-checkbox>
        </v-card-text>
        <v-card-text>
          <v-container class="pa-0">
            <v-row dense>
              <v-col cols="4" md="2">
                <!-- Match Up Card -->
                <v-card height="100%">
                  <v-card-title >
                    Possible Match Ups
                  </v-card-title>
                  <v-card-text :class="{'pa-1': $vuetify.breakpoint.xsOnly}">
                    <v-row dense>
                      <v-col cols="12" v-for="(t, i) in matchups" :key="`mu${i}`">
                        <v-tooltip right>
                          <template v-slot:activator="{ on }">
                            <v-btn
                              v-on="on"
                              color="color3 color3Text--text"
                              block
                              rounded
                              @click.stop="addMatch(t)"
                            >
                              {{t.home}} vs {{t.away}}
                              <v-spacer></v-spacer>
                              <v-icon right>fas fa-plus-circle</v-icon>
                            </v-btn>
                          </template>
                          <span>Click to add</span>
                        </v-tooltip>
                      </v-col>
                    </v-row>
                  </v-card-text>
                </v-card>
              </v-col>
              <v-col cols="8" md="10">
                <!-- Matches Card -->
                <v-card height="100%">
                  <v-card-title>
                    Matches
                  </v-card-title>
                  <v-card-text>
                    <v-row dense>
                      <v-col cols="12" sm="6" v-for="(m, i) in matches" :key="`m-${m.number}`">
                        <v-card>
                          <v-card-title class="title py-0 pr-0">
                            Match {{m.number}}
                            <v-spacer></v-spacer>
                            <v-btn color="error" text icon class="xsmall" @click.stop="dropMatch(i)">
                              <v-icon>fas fa-trash</v-icon>
                            </v-btn>
                          </v-card-title>
                          <v-card-text>
                            <v-row dense>
                              <v-col cols="12" sm="6">
                                <v-autocomplete
                                  v-model="m.home"
                                  :items="slots.filter(f => f !== m.away)"
                                  hide-details
                                  label="Home"
                                ></v-autocomplete>
                              </v-col>
                              <v-col cols="12" sm="6">
                                <v-autocomplete
                                  v-model="m.away"
                                  :items="slots.filter(f => f !== m.home)"
                                  hide-details
                                  label="Away"
                                ></v-autocomplete>
                              </v-col>
                              <v-col cols="12">
                                <v-autocomplete
                                  v-model="m.ref"
                                  :items="slots.filter(f => f !== m.home && f !== m.away)"
                                  hide-details
                                  label="Ref"
                                ></v-autocomplete>
                              </v-col>
                            </v-row>
                          </v-card-text>
                        </v-card>
                      </v-col>
                    </v-row>
                  </v-card-text>
                </v-card>
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>
      </v-window-item>
      <v-window-item :value="3">
        <v-card-text class="pt-0">
          <div class="text-right">
            <v-btn color="color3" text small @click="stepI = 1">regular</v-btn>
          </div>
          <v-text-field
            label="Number of Players"
            type="number"
            v-model="poolsOf"
          ></v-text-field>
        </v-card-text>
        <v-card-text>
          <v-container class="pa-0">
            <v-row dense>
              <v-col cols="4" md="2">
                <!-- Match Up Card -->
                <v-card height="100%">
                  <v-card-title >
                    Players
                  </v-card-title>
                  <v-card-text :class="{'pa-1': $vuetify.breakpoint.xsOnly}">
                    <v-row dense>
                      <v-col cols="12" v-for="s in slots" :key="`mu${s}`">
                        <v-avatar size="24" :color="teamColorsInverse[s - 1]">
                          <span class="white--text">{{s}}</span>
                        </v-avatar>
                        Matches - {{teamCounts[s-1]}}
                      </v-col>
                    </v-row>
                  </v-card-text>
                </v-card>
              </v-col>
              <v-col cols="8" md="10">
                <!-- Matches Card -->
                <v-card height="100%">
                  <v-card-title class="justify-space-between">
                    <span>Matches</span>
                    <v-btn color="color3 color3Text--text" @click="addMatch(false)">Add Match</v-btn>
                  </v-card-title>
                  <v-card-text>
                    <v-row dense>
                      <v-col cols="12" sm="6" v-for="(m, i) in matches" :key="`m-${m.number}`">
                        <v-card>
                          <v-card-title class="title py-0 pr-0">
                            Match {{m.number}}
                            <v-spacer></v-spacer>
                            <v-btn color="error" text icon class="xsmall" @click.stop="dropMatch(i)">
                              <v-icon>fas fa-trash</v-icon>
                            </v-btn>
                          </v-card-title>
                          <v-card-text>
                            <v-row dense>
                              <v-col cols="12" sm="6">
                                <v-select
                                  v-model="m.homes"
                                  :items="slots.filter(f => !m.aways.includes(f))"
                                  label="Home"
                                  multiple
                                  color="color3"
                                  item-color="color3"
                                  chips
                                >
                                  <template v-slot:selection="{ item }">
                                    <v-chip :color="teamColorsInverse[item - 1]">
                                      <span class="white--text">{{ item }}</span>
                                    </v-chip>
                                  </template>
                                </v-select>
                              </v-col>
                              <v-col cols="12" sm="6">
                                <v-select
                                  v-model="m.aways"
                                  :items="slots.filter(f => !m.homes.includes(f))"
                                  hide-details
                                  label="Away"
                                  multiple
                                  color="color3"
                                  item-color="color3"
                                  chips
                                >
                                  <template v-slot:selection="{ item }">
                                    <v-chip :color="teamColorsInverse[item - 1]">
                                      <span class="white--text">{{ item }}</span>
                                    </v-chip>
                                  </template>
                                </v-select>
                              </v-col>
                              <v-col cols="12">
                                <v-select
                                  v-model="m.ref"
                                  :items="slots.filter(f => !m.used.includes(f))"
                                  hide-details
                                  label="Ref"
                                  color="color3"
                                  item-color="color3"
                                  chips
                                >
                                  <template v-slot:selection="{ item }">
                                    <v-chip :color="teamColorsInverse[item - 1]">
                                      <span class="white--text">{{ item }}</span>
                                    </v-chip>
                                  </template>
                                </v-select>
                              </v-col>
                            </v-row>
                          </v-card-text>
                        </v-card>
                      </v-col>
                    </v-row>
                    <v-row dense>
                      <v-col cols="12" class="text-end">
                        <v-btn color="color3 color3Text--text" @click="addMatch(false)">Add Match</v-btn>
                      </v-col>
                    </v-row>
                  </v-card-text>
                </v-card>
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>
      </v-window-item>
      <!-- SUMMARY -->
      <v-window-item :value="2">
        <v-card-text>
          <v-container>
            <v-row dense>
              <v-col cols="6">
                <v-text-field
                  label="Name"
                  v-model="name"
                ></v-text-field>
              </v-col>
              <v-col cols="6">
                <v-checkbox
                  label="Set as my default"
                  v-model="isDefault"
                  color="success"></v-checkbox>
              </v-col>
              <v-col cols="6">
                <v-textarea
                  label="Description"
                  v-model="description"
                  auto-grow
                  :rows="1"
                ></v-textarea>
              </v-col>
              <v-col cols="6">
                <v-slide-x-reverse-transition leave-absolute mode="out-in">
                  <v-btn
                    v-if="!matchSetting"
                    color="color3 color3Text--text"
                    @click.stop="addMatchsetting"
                  >add default match settins</v-btn>
                </v-slide-x-reverse-transition>
                <v-slide-x-reverse-transition leave-absolute mode="out-in" group>
                  <match-settings
                    key="ms"
                    v-if="matchSetting"
                    :setting="matchSetting"
                    :edit="true"
                  ></match-settings>
                  <div class="text-end" key="btn-div" v-if="matchSetting">
                    <v-btn
                      color="error"
                      @click.stop="matchSetting = null"
                      text x-small
                    >remove match settings</v-btn>
                  </div>
                </v-slide-x-reverse-transition>
              </v-col>
              <v-col cols="12" class="title">
                Summary
              </v-col>
              <v-col cols="12" class="title">
                <v-data-table
                  :headers="[
                    {text: kob ? 'Player' : 'Team', value: 'team'},
                    {text: 'Playing', value: 'play'},
                    {text: 'Working', value: 'work'},
                    {text: 'With', value: 'with'},
                    {text: 'Against', value: 'against'}
                  ]"
                  :items="summary"
                  class="elevation-1"
                  dense
                  :options.sync="page"
                  hide-default-footer
                >
                  <template v-slot:[`item.team`]="{item}">
                    <strong>{{kob ? 'Player' : 'Team'}} {{item.number}}</strong>
                  </template>
                  <template v-slot:[`item.play`]="{item}">
                    {{item.matches.length}}
                  </template>
                  <template v-slot:[`item.work`]="{item}">
                    {{item.working.length}}
                  </template>
                </v-data-table>
              </v-col>
            </v-row>
            <v-row dense v-if="false">
              <v-col cols="12">
                <v-textarea :value="printTemplate">
                </v-textarea>
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>
      </v-window-item>
    </v-window>
    <v-divider></v-divider>
    <!-- ACTION BUTTONS -->
    <v-card-actions>
      <v-fab-transition>
        <v-btn
          fab
          v-if="step.leftFab"
          :color="step.leftFab.color"
          @click.stop="step.leftFab.click"
          :disabled="step.leftFab.disabled"
        >
          <v-icon>{{step.leftFab.icon}}</v-icon>
        </v-btn>
      </v-fab-transition>
      <v-spacer></v-spacer>
      <v-fab-transition>
        <v-btn
          fab
          v-if="step.rightFab"
          :color="step.rightFab.color"
          @click.stop="step.rightFab.click"
          :disabled="step.rightFab.disabled"
          :loading="step.rightFab.loading"
        >
          <v-icon>{{step.rightFab.icon}}</v-icon>
        </v-btn>
      </v-fab-transition>
    </v-card-actions>
  </v-card>
</template>

<script>
import MatchTemplate from '@/classes/MatchTemplate'
import { mapGetters } from 'vuex'
import flatten from '../../helpers/ArrayFlatten'
import { firstBy } from 'thenby'
import MatchSettings from '@/components/Tournament/RoundSettings/MatchSettings.vue'
import MatchSettingsJs from '@/classes/MatchSettings'

export default {
  props: ['username', 'poolTemplate', 'active'],
  data () {
    return {
      stepI: 1,
      poolsOf: null,
      matches: [],
      name: null,
      description: null,
      loading: false,
      kob: false,
      page: { itemsPerPage: -1 },
      isDefault: false,
      matchSetting: null,
      allowDups: false
    }
  },
  computed: {
    ...mapGetters(['teamColorsInverse']),
    isNew () {
      return !(this.poolTemplate && this.poolTemplate.id)
    },
    printTemplate () {
      return JSON.stringify(this.matches.map((m, i) => {
        return [
          { text: `${i + 1}`, alignment: 'center', border: [false, false, false, false], margin: [0, 10, 0, 0] },
          { text: `${m.home} vs ${m.away}`, alignment: 'center', border: [false, false, false, false], margin: [0, 10, 0, 0] },
          { text: `${m.ref}`, alignment: 'center', border: [false, false, false, false], margin: [0, 10, 0, 0] },
          { text: `${m.home}-`, alignment: 'right', border: [false, false, false, false], margin: [0, 10, 0, 0] },
          { text: '', border: [false, false, false, true], margin: [0, 10, 0, 0] },
          { text: `${m.away}-`, alignment: 'right', border: [false, false, false, false], margin: [0, 10, 0, 0] },
          { text: '', border: [false, false, false, true], margin: [0, 10, 0, 0] }
        ]
      }))
    },
    slots () {
      if (this.poolsOf) {
        var teams = [...Array(+this.poolsOf + 1).keys()]
        teams.shift()
        return teams
      }
      return []
    },
    teamCounts () {
      return this.slots.map(s => {
        return this.matches.filter(m => m.used.includes(s)).length
      })
    },
    summary () {
      return this.kob ? this.kobSummary : this.regSummary
    },
    regSummary () {
      return this.slots.map(m => {
        return {
          number: m,
          matches: this.matches.filter(f => f.home === m || f.away === m),
          working: this.matches.filter(f => f.ref === m)
        }
      })
    },
    kobSummary () {
      return this.slots.map(m => {
        return {
          number: m,
          matches: this.matches.filter(f => f.used.includes(m)),
          working: this.matches.filter(f => f.ref === m),
          with: flatten(this.matches.filter(f => f.used.includes(m)).map(a => {
            return a.homes.includes(m) ? a.homes.filter(f => f !== m) : a.aways.filter(f => f !== m)
          })).sort(firstBy(Number)),
          against: flatten(this.matches.filter(f => f.used.includes(m)).map(a => {
            return a.homes.includes(m) ? a.aways : a.homes
          })).sort(firstBy(Number))
        }
      })
    },
    matchups () {
      var combos = []
      for (const h of this.slots) {
        for (const a of this.slots.filter(f => f > h)) {
          const x = this.matches.find(f => f.home === h && f.away === a)
          if (!x || this.allowDups) combos.push(new MatchTemplate(h, a))
        }
      }
      return combos
    },
    step () {
      switch (this.stepI) {
        case 1:
        case 3:
          return {
            leftFab: {
              color: 'error white--text',
              icon: 'fas fa-times',
              click: () => { this.$emit('cancel-click') }
            },
            rightFab: {
              color: 'color3 color3Text--text',
              icon: 'fas fa-caret-right',
              click: () => { this.stepI = 2 },
              disabled: !this.poolsOf
            }
          }
        case 2:
          return {
            leftFab: {
              color: 'color3Text color3--text',
              icon: 'fas fa-caret-left',
              click: () => { this.stepI = this.kob ? 3 : 1 }
            },
            rightFab: {
              color: 'color3 color3Text--text',
              icon: 'fas fa-save',
              click: this.save,
              loading: this.loading
            }
          }
      }
      return null
    },
    matchesTemplate () {
      return this.matches && this.matches.map(m => {
        return [m.number, m.home, m.away, (m.ref || 0)].join(',')
      }).join('|')
    },
    kobTemplate () {
      return this.matches && this.matches.map(m => {
        return [m.number, m.kobHome, m.kobAway, (m.ref || 0)].join(',')
      }).join('|')
    },
    dto () {
      return {
        id: this.poolTemplate ? this.poolTemplate.id : null,
        type: 'Pool',
        name: this.name,
        description: this.description,
        default: this.isDefault,
        matchSetting: this.matchSetting ? this.matchSetting.dto : null,
        template: {
          poolsOf: this.poolsOf,
          matches: this.kob ? `kob|${this.kobTemplate}` : this.matchesTemplate
        }
      }
    }
  },
  methods: {
    addMatchsetting () {
      this.matchSetting = new MatchSettingsJs({
        matchTypeId: 1
      }, true)
    },
    addMatch (template) {
      const t = template || new MatchTemplate()
      t.number = this.matches.length + 1
      this.matches.push(t)
    },
    dropMatch (i) {
      this.matches.splice(i, 1)
      this.renumber()
    },
    renumber () {
      let i = 1
      this.matches.forEach(f => { f.number = i++ })
    },
    save () {
      this.loading = true
      this.$VBL.organization.templates.post(this.username, this.dto)
        .then(r => {
          this.$emit('saved', r.data)
        })
        .catch(e => { console.log(e.response) })
        .finally(() => { this.loading = false })
    },
    loadTemplate () {
      if (!this.poolTemplate) return
      const pt = this.poolTemplate
      this.poolsOf = pt.template.poolsOf
      this.name = pt.name
      this.description = pt.description
      this.isDefault = pt.default
      this.matchSetting = pt.matchSetting ? new MatchSettingsJs(pt.matchSetting) : null
      const matches = pt.template.matches.split('|')
      this.kob = !!matches.find(f => f === 'kob')
      matches.filter(f => f !== 'kob').forEach(m => {
        const args = m.split(',')
        const temp = new MatchTemplate(args[1], args[2], +args[3])
        this.addMatch(temp)
      })
      if (this.kob) this.stepI = 3
    }
  },
  watch: {
    active: function (val) {
      if (val) {
        this.loadTemplate()
      }
    },
    stepI: function (val) {
      if (val && val === 3) this.kob = true
      if (val && val === 1) this.kob = false
    }
  },
  components: {
    MatchSettings
  },
  mounted () {
    this.loadTemplate()
  }
}
</script>

<style>

</style>
